import { Box, styled } from "@mui/material";

export const StyledWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(1),
}))

export const StyledContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  border: `1px solid ${theme.palette.grey[300]}`,
  width: "100%",
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  borderRadius: "4px",
}))