import { colors } from "../../../theme/colors";
import { fallbackColorsSecondary } from "../../../utils/fallbackColors";
import type { ElectricityByTypesAggregatesResponseModel } from "../../../store/widgetContents/widgetContents.types";

export const parseData = (data?: ElectricityByTypesAggregatesResponseModel) => {
  if (!data) return []

  const fallbackColors = fallbackColorsSecondary()

  const getUniqueColor = () => {
    if (fallbackColors.length === 0) {
      return colors.chartSecondaryBlue
    }
    const randomIndex = Math.floor(Math.random() * fallbackColors.length);
    const color = fallbackColors[randomIndex];
    fallbackColors.splice(randomIndex, 1);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return color
  };

  return data.types.map(type => ({
    id: `${type.name.replace(/ /gu, "-")}-${type.valuePercentage}`,
    value: type.valuePercentage,
    name: type.name,
    color: type.colour.length ? type.colour : getUniqueColor()
  }));
};