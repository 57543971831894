import type { RootState } from "../store.types"
import type { AuthState } from "./auth.types"

export const selectToken: ((state: RootState) => AuthState["accessToken"]) = ({ auth }) => auth.accessToken

export const selectUserData: ((state: RootState) => AuthState["userData"]) = ({ auth }) => auth.userData

export const selectLanguageId: ((state: RootState) => AuthState["userData"]["languageId"]) = ({ auth }) => auth.userData.languageId

export const selectCompanyName: ((state: RootState) => AuthState["userData"]["companyName"]) = ({ auth }) => auth.userData.companyName

export const selectUserLocale: ((state: RootState) => AuthState["userData"]["locale"]) = ({ auth }) => auth.userData.locale