export enum WidgetType {
  LINKS = "LINKS",
  GUIDES = "GUIDES",
  TOTALS = "TOTALS",
  CATEGORIES = "CATEGORIES",
  ELECTRICITY = "ELECTRICITY",
  SOURCES = "SOURCES",
  ELECTRICITY_GRAPH = "ELECTRICITY_GRAPH"
}

export type WidgetSelectionResponseModel = {
  widgetId: number;
  show: boolean;
  name: string;
  description: string;
  code: string;
  displayOrder: number;
};