import { apiService } from "../store.service";
import type { WidgetSelectionResponseModel } from "./widgetSelections.types";

import { getWidgetSelectionsConfig, updateWidgetOrderConfig, updateWidgetSelectionConfig } from "./widgetSelections.config";

export const WidgetSelectionsApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["widgetsSelection"]
  })
  .injectEndpoints({
    endpoints: build => ({
      getWidgetSelections: build.query<WidgetSelectionResponseModel[], void>({
        query: () => ({
          ...getWidgetSelectionsConfig,
        }),
        providesTags: ["widgetsSelection"]
      }),

      updateWidgetSelection: build.mutation<void, { widgetId: number, show: boolean }>({
        query: ({ widgetId, show }) => ({
          ...updateWidgetSelectionConfig(widgetId),
          data: {
            widgetId,
            show
          }
        }),
        invalidatesTags: ["widgetsSelection"]
      }),

      updateWidgetOrder: build.mutation<void, { widgetId: number, newPosition: number }>({
        query: ({ widgetId, newPosition }) => ({
          ...updateWidgetOrderConfig(widgetId),
          params: {
            newPosition
          }
        }),
        invalidatesTags: ["widgetsSelection"]
      }),
    }),
  });

export const {
  useGetWidgetSelectionsQuery,
  useUpdateWidgetSelectionMutation,
  useUpdateWidgetOrderMutation,
} = WidgetSelectionsApi