import type { FC} from "react";
import { useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { useGetElectricityConsumptionByTypeQuery } from "../../../store/widgetContents/widgetContents.api";
import { LineChart } from "../../charts/LineChart/LineChart.component";
import { Loader } from "../../common/Loader/Loader.component";
import { ErrorTile } from "../ErrorTile/ErrorTile.component";
import { ElectricityUsageGraphTileNoData } from "./ElectricityUsageGraphTileNoData/ElectricityUsageGraphTileNoData.component";
import { mergeData } from "./ElectricityUsageGraphTile.utils";
import { StyledPaper } from "../widgets.styles";

export const ElectricityUsageGraphTile: FC<{ title: string }> = ({ title }) => {
  const { data, isLoading, error } = useGetElectricityConsumptionByTypeQuery(undefined, { refetchOnMountOrArgChange: true })

  const electricityEmissionsPerDate = useMemo(() => data 
    ? mergeData(data) 
    : [], [data])

  if (isLoading) {
    return (
      <StyledPaper>
        <Loader withoutText my="auto" />
      </StyledPaper>
    )
  }

  if (error) {
    return <ErrorTile title={title} />
  }

  if (!electricityEmissionsPerDate.length) {
    return <ElectricityUsageGraphTileNoData title={title} />
  }

  return (
    <StyledPaper>
      <Typography
        variant="h2"
        color="text.dark"
        mb={1.5}
      >
        {title}
      </Typography>

      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <LineChart data={electricityEmissionsPerDate} />
      </Box>
    </StyledPaper>
  )
}