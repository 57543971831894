import { apiService } from "../store.service";
import type {
  ElectricityByTypeResponseModel,
  ElectricityByTypesAggregatesResponseModel,
  EmissionAggregatesByScopeResponseModel,
  EmissionsByCategoryResponseModel,
  EmissionsTopResponseModel,
  FuelAggregateResponseModel,
  FuelAggregateWidgetSettingsResponseModel,
  UserGuideSimpleResponseModel,
  UtilityAggregateResponseModel,
  UtilityAggregateWidgetSettings,
  WidgetYearSetting,
  WidgetYearSettingPutModel
} from "./widgetContents.types";

import {
  getElectricityConsumptionAggregatesConfig,
  getElectricityConsumptionByTypeConfig,
  getFuelAggregatesConfig,
  getFuelAggregatesWidgetSettingsConfig,
  getRankedEmissionsByCategoriesConfig,
  getScopesAggregatesConfig,
  getTopEmissionSourcesConfig,
  getUserGuidesConfig,
  getUtilityAggregatesConfig,
  getUtilityAggregatesWidgetSettingsConfig,
  getYearSettingValueConfig,
  putFuelAggregatesWidgetSettingsConfig,
  putUtilityAggregatesWidgetSettingsConfig,
  putYearSettingValueConfig
} from "./widgetContents.config";

export const WidgetContentsApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["widgetFuelAggregates", "widgetUtilityAggregates", "filteringByYear"]
  })
  .injectEndpoints({
    endpoints: build => ({
      getFuelAggregatesWidgetSettings: build.query<FuelAggregateWidgetSettingsResponseModel, void>({
        query: () => ({
          ...getFuelAggregatesWidgetSettingsConfig,
        }),
      }),

      putFuelAggregatesWidgetSettings: build.mutation<FuelAggregateWidgetSettingsResponseModel, { duration: string }>({
        query: ({ duration }) => ({
          ...putFuelAggregatesWidgetSettingsConfig,
          data: {
            duration
          }
        }),
        invalidatesTags: ["widgetFuelAggregates"]
      }),

      getFuelAggregates: build.query<FuelAggregateResponseModel[], { locationId?: number | string }>({
        query: ({ locationId }) => ({
          ...getFuelAggregatesConfig,
          params: {
            locationId
          }
        }),
        providesTags: ["widgetFuelAggregates"]
      }),

      getUtilityAggregatesWidgetSettings: build.query<UtilityAggregateWidgetSettings, void>({
        query: () => ({
          ...getUtilityAggregatesWidgetSettingsConfig,
        }),
      }),

      putUtilityAggregatesWidgetSettings: build.mutation<UtilityAggregateWidgetSettings, { duration: string }>({
        query: ({ duration }) => ({
          ...putUtilityAggregatesWidgetSettingsConfig,
          data: {
            duration
          }
        }),
        invalidatesTags: ["widgetUtilityAggregates"]
      }),

      getUtilityAggregates: build.query<UtilityAggregateResponseModel[], { locationId?: number | string }>({
        query: ({ locationId }) => ({
          ...getUtilityAggregatesConfig,
          params: {
            locationId
          }
        }),
        providesTags: ["widgetUtilityAggregates"]
      }),

      getScopesAggregates: build.query<EmissionAggregatesByScopeResponseModel, void>({
        query: () => ({
          ...getScopesAggregatesConfig,
        }),
        providesTags: ["filteringByYear"]
      }),

      getRankedEmissionsByCategories: build.query<EmissionsByCategoryResponseModel, void>({
        query: () => ({
          ...getRankedEmissionsByCategoriesConfig,
        }),
        providesTags: ["filteringByYear"]
      }),

      getTopEmissionSources: build.query<EmissionsTopResponseModel, { count: number }>({
        query: ({ count }) => ({
          ...getTopEmissionSourcesConfig(count),
        }),
        providesTags: ["filteringByYear"]
      }),

      getElectricityConsumptionByType: build.query<ElectricityByTypeResponseModel, void>({
        query: () => ({
          ...getElectricityConsumptionByTypeConfig,
        }),
        providesTags: ["filteringByYear"]
      }),

      getElectricityConsumptionAggregates: build.query<ElectricityByTypesAggregatesResponseModel, void>({
        query: () => ({
          ...getElectricityConsumptionAggregatesConfig,
        }),
        providesTags: ["filteringByYear"]
      }),

      getUserGuides: build.query<{ [key: string]: UserGuideSimpleResponseModel }, void>({
        query: () => ({
          ...getUserGuidesConfig,
        }),
      }),

      getYearSettingValueConfig: build.query<WidgetYearSetting, void>({
        query: () => ({
          ...getYearSettingValueConfig,
        }),
        providesTags: ["filteringByYear"]
      }),

      putYearSettingValueConfig: build.mutation<void, WidgetYearSettingPutModel>({
        query: ({ year }) => ({
          ...putYearSettingValueConfig,
          data: {
            year
          }
        }),
        invalidatesTags: ["filteringByYear"]
      }),
    }),
  });

export const {
  useGetFuelAggregatesWidgetSettingsQuery,
  usePutFuelAggregatesWidgetSettingsMutation,
  useGetFuelAggregatesQuery,
  useGetUtilityAggregatesWidgetSettingsQuery,
  usePutUtilityAggregatesWidgetSettingsMutation,
  useGetUtilityAggregatesQuery,
  useGetScopesAggregatesQuery,
  useGetRankedEmissionsByCategoriesQuery,
  useGetTopEmissionSourcesQuery,
  useGetElectricityConsumptionByTypeQuery,
  useGetElectricityConsumptionAggregatesQuery,
  useGetUserGuidesQuery,
  useGetYearSettingValueConfigQuery,
  usePutYearSettingValueConfigMutation,
} = WidgetContentsApi