import type { RouteConfig } from "../../api/rest/api"

export const getUserSettingsConfig: RouteConfig = {
  method: "GET",
  url: "/api/UserSettings",
  // token: required
}

export const updateHideUserTourConfig: RouteConfig = {
  method: "PUT",
  url: "/api/UserSettings/Tour",
  // token: required
}

export const updateHideAddLocationTooltipConfig: RouteConfig = {
  method: "PUT",
  url: "/api/UserSettings/AddLocations",
  // token: required
}

export const updateHideAddDataTooltipConfig: RouteConfig = {
  method: "PUT",
  url: "/api/UserSettings/AddData",
  // token: required
}

// Update hide dashboard company tile setting.
export const updateHideDashboardCompanyTileConfig: RouteConfig = {
  method: "PUT",
  url: "/api/UserSettings/CompanyTile",
  // token: required
}