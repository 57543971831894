import type { FC } from "react";
import { Typography } from "@mui/material";

import { NoDataCover } from "../../NoDataCover/NoDataCover.component";
import { StyledPaper } from "../../widgets.styles";

export const ElectricityUsageGraphTileNoData: FC<{ title: string }> = ({ title }) => (
  <StyledPaper>
    <Typography
      variant="h2"
      color="text.dark"
      mb={1.5}
    >
      {title}
    </Typography>

    <NoDataCover />
  </StyledPaper>
)