import { NavLink } from "react-router-dom";
import { styled,Typography } from "@mui/material";

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 500,
  marginBottom: theme.spacing(1.5),
  "& span": {
    color: theme.palette.text.primary,
  }
}))

export const StyledLink = styled(NavLink)(({ theme }) => ({
  "& > p": {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary[700],
    }
  }
}))