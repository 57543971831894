import { NavLink } from "react-router-dom";
import { styled } from "@mui/material";

export const StyledLink = styled(NavLink)(({ theme }) => ({
  "& > p": {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary[700],
    }
  }
}))