import type { FC } from "react";
import { Box, Typography } from "@mui/material";

import type { EntryValue } from "../AreaChart.types";
import { StyledDot, StyledWrapper } from "./AreaChartLegend.styles";

type AreaChartLegendProps = {
  entries: EntryValue[]
  title: string
}

export const AreaChartLegend: FC<AreaChartLegendProps> = ({ entries, title }) => (
  <Box
    width="100%"
    display="flex"
    ml={6}
    flexDirection="column"
  >
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="flex-end"
    >
      {entries.map( ({ color, name }) => (
        <StyledWrapper
          key={name}
        >
          <StyledDot
            component='span'
            bgcolor={color}
          />
          <Typography variant='body2'>
            {name}
          </Typography>
        </StyledWrapper>
      ))}
    </Box>
    <Typography
      variant="body2"
      color="text.secondary"
      alignSelf="flex-start"
    >
      {title}
    </Typography>
  </Box>
)