import type { FC } from "react";
import { Fragment } from "react";
import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import { Area, AreaChart as AreaChartRecharts, CartesianGrid, Legend, ResponsiveContainer,Tooltip, XAxis, YAxis } from "recharts";

import { AreaChartLegend } from "./AreaChartLegend/AreaChartLegend.component";
import { AreaChartTooltip } from "./AreaChartTooltip/AreaChartTooltip.component";
import type { AreaChartProps } from "./AreaChart.types";

export const AreaChart: FC<AreaChartProps> = ({ title, data }) => {
  const theme = useTheme()
  const entries = Object.values(data[0].entries)

  if(!data.length) {
    return null
  }

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={500}>
      <AreaChartRecharts
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid stroke={theme.palette.grey[300]} />

        <XAxis
          dataKey="date"
          axisLine={{ stroke: theme.palette.grey[300] }}
          tickLine={{ stroke: theme.palette.grey[300] }}
          tickFormatter={(date: Date) => dayjs(date).format("MMM DD")}
          tick={{
            stroke: theme.palette.grey[500],
            fontSize: theme.typography.body2.fontSize,
            strokeWidth: 0,
          }}
        />

        <YAxis
          strokeWidth={0.5}
          axisLine={{ stroke: theme.palette.grey[300] }}
          tickLine={{ stroke: theme.palette.grey[300] }}
          tick={{
            stroke: theme.palette.grey[500],
            fontSize: theme.typography.body2.fontSize,
            strokeWidth: 0,
          }}
        />

        <Tooltip
          content={<AreaChartTooltip />}
          position={{ y: 180 }}
          cursor={{
            stroke: theme.palette.primary[200],
            strokeDasharray: "2 8",
            strokeWidth: 2,
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />

        <Legend
          layout="horizontal"
          verticalAlign="top"
          align="right"
          iconType="circle"
          content={<AreaChartLegend entries={entries} title={title} />}
          wrapperStyle={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "32px",
          }}
        />
        
        {entries.map(({ name, color }) => {
          const entryName = name.replace(/ /gu,"")

          return (
            <Fragment key={entryName}>
              <defs>
                <linearGradient id={entryName} x1="418.75" y1="0" x2="418.75" y2="382.319" gradientUnits="userSpaceOnUse">
                  <stop offset="0" stopColor={`${color}`} />
                  <stop offset="1" stopColor={`${color}10`} />
                </linearGradient>
              </defs>
  
              <Area
                dataKey={`entries.${name}.carbonEquivalent`}
                stroke={color}
                strokeWidth={2}
                fill={`url(#${entryName})`}
                activeDot={{
                  stroke: theme.palette.primary[200],
                  strokeWidth: 2,
                  r: 6,
                }}
              />
            </Fragment>
          )
        })}
      </AreaChartRecharts>
    </ResponsiveContainer>
  )
}