import { Grid } from "@mui/material"

import { ElectricityUsageGraphTile } from "../../components/widgets/ElectricityUsageGraphTile/ElectricityUsageGraphTile.component"
import { ElectricityUsageTile } from "../../components/widgets/ElectricityUsageTile/ElectricityUsageTile.component"
import { EmissionsByCategoryTile } from "../../components/widgets/EmissionsByCategoryTile/EmissionsByCategoryTile.component"
import { GuidesTile } from "../../components/widgets/GuidesTile/GuidesTile.component"
import { ShortcutsTile } from "../../components/widgets/ShortcutsTile/ShortcutsTile.component"
import { SummaryTile } from "../../components/widgets/SummaryTile/SummaryTile.component"
import { TopEmissionSourcesTile } from "../../components/widgets/TopEmissionSourcesTile/TopEmissionSourcesTile.component"
import type { WidgetSelectionResponseModel} from "../../store/widgetSelections/widgetSelections.types";
import { WidgetType } from "../../store/widgetSelections/widgetSelections.types"

export const renderWidget = (widget: WidgetSelectionResponseModel) => {
  switch(widget.code) {
    case WidgetType.LINKS:
      return (
        <Grid item xs={12} lg={6} key={widget.widgetId}>
          <ShortcutsTile title={widget.name} />
        </Grid>
      )
    case WidgetType.GUIDES:
      return (
        <Grid item xs={12} lg={6} key={widget.widgetId}>
          <GuidesTile title={widget.name} />
        </Grid>
      )
    case WidgetType.TOTALS:
      return (
        <Grid item xs={12} lg={6} key={widget.widgetId}>
          <SummaryTile title={widget.name} />
        </Grid>
      )
    case WidgetType.CATEGORIES:
      return (
        <Grid item xs={12} lg={6} key={widget.widgetId}>
          <EmissionsByCategoryTile title={widget.name} />
        </Grid>
      )
    case WidgetType.ELECTRICITY:
      return (
        <Grid item xs={12} lg={6} key={widget.widgetId}>
          <ElectricityUsageTile title={widget.name} />
        </Grid>
      )
    case WidgetType.SOURCES:
      return (
        <Grid item xs={12} lg={6} key={widget.widgetId}>
          <TopEmissionSourcesTile title={widget.name} />
        </Grid>
      )
    case WidgetType.ELECTRICITY_GRAPH:
      return (
        <Grid item xs={12} lg={6} key={widget.widgetId}>
          <ElectricityUsageGraphTile title={widget.name} />
        </Grid>
      )
    default:
      break
  }
}