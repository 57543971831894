import { theme } from "../../../../theme/theme";

export const mock = [
  {
    "id": 1,
    "value": 330,
    "name": "Fuels (stationary)",
    "color": theme.palette.grey[500]
  },
  {
    "id": 2,
    "value": 250,
    "name": "Fuels (mobile)",
    "color": theme.palette.grey[400]
  },
  {
    "id": 3,
    "value": 200,
    "name": "Process emissions",
    "color": theme.palette.grey[300]
  },
  {
    "id": 4,
    "value": 150,
    "name": "Unorganised emissions",
    "color": theme.palette.grey[200]
  },
  {
    "id": 5,
    "value": 100,
    "name": "Electricity",
    "color": theme.palette.grey[100]
  }
]