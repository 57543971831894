import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import type { TooltipProps } from "recharts";

import { theme } from "../../../../theme/theme";
import type { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import type { LineChartDataModel } from "../LineChart.types";
import { StyledRectColor, StyledTooltip } from "./LineChartTooltip.styles";

export const LineChartTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const data = payload?.[0]?.payload as LineChartDataModel | null
  if (!data || !active) {
    return null
  }
  const { date, entries, unit } = data

  return (
    <StyledTooltip title="">
      <Box sx={{ boxShadow: theme.boxShadows.xl }}>
        <Box
          display="flex"
          justifyContent="space-between"
          minWidth="200px"
          mb={1}
        >
          <Typography
            variant="body1"
            color="text.primary"
          >
            {dayjs(date).format("DD MMMM YYYY")}
          </Typography>
        </Box>

        {Object.values(entries).map(({ name, value, color }) => (
          <Box
            key={name}
            display="flex"
            alignItems="center"
          >
            <StyledRectColor color={color} />
            
            <Typography
              variant="body1"
              color="text.primary"
            >
              {name}:&nbsp;
              {value} {unit}
            </Typography>
          </Box>
        ))}
      </Box>
    </StyledTooltip>
  )
}