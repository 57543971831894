import { useMemo } from "react";
import type { TFunction } from "i18next";

import { useAppSelector } from "../../../hooks/storeHooks";
import { selectUserData } from "../../../store/auth/auth.selectors";
import type { MenuItemsConfigType } from "./MenuList.types";

export const useMenuConfig = (t: TFunction): MenuItemsConfigType[] => {
  const userData = useAppSelector(selectUserData)
  const shouldDisplay = userData.roleName === "Admin"

  const menuItems = useMemo(() => [
    {
      text: t("common:menu:overview"),
      iconName: "home",
      navigateTo: "/",
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:emissionReport"),
      iconName: "pie-chart",
      navigateTo: "/explorer",
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:inputData"),
      iconName: "map",
      navigateTo: "/locations",
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:reports"),
      iconName: "file",
      navigateTo: "/reports",
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:scenarios"),
      iconName: "tools",
      navigateTo: "/scenarios",
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:valueChainEmissions"),
      iconName: "chain",
      navigateTo: "/value-chain",
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:offsettingMarketplace"),
      iconName: "company",
      navigateTo: "/*",
      disabled: true,
      visible: true
    },
    {
      text: t("common:menu:sustainabilityMarketplace"),
      iconName: "coins",
      navigateTo: "/*",
      disabled: true,
      visible: true
    },
    {
      text: t("common:menu:knowledgeBase"),
      iconName: "knowledge",
      navigateTo: "/*",
      disabled: true,
      visible: true
    },
    {
      text: t("common:menu:settings"),
      iconName: "settings",
      navigateTo: "/settings",
      disabled: false,
      visible: shouldDisplay
    },
  ], [t])
  
  return menuItems
}
