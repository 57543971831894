import type { RouteConfig } from "../../api/rest/api"

// Get all user widget selections
export const getWidgetSelectionsConfig: RouteConfig = {
  method: "GET",
  url: "/api/Widgets/Selections",
  // token: required
}

// Update widget selection
export const updateWidgetSelectionConfig = (widgetId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Widgets/${widgetId}/Selections`,
  // token: required
})

// Update order for this widget
export const updateWidgetOrderConfig = (widgetId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Widgets/${widgetId}/Selections/Order`,
  // token: required
})