import { colors } from "../colors"

export const palette = {
  text: {
    primary: colors.textPrimary,
    secondary: colors.textSecondary,
    dark: colors.textDark,
    light: colors.textLight,
  },
  background: {
    default: colors.grey200,
    appBar: colors.grey100,
    paper: colors.white
  },
  primary: {
    100: colors.primary100,
    200: colors.primary200,
    300: colors.primary300,
    main: colors.primary500,
    700: colors.primary700,
    900: colors.primary900,
  },
  error: {
    main: colors.errorMain,
    light: colors.errorLight,
    dark: colors.errorDark,
  },
  warning: {
    main: colors.warningMain,
    light: colors.warningLight,
    dark: colors.warningDark,
  },
  success: {
    main: colors.successMain,
    light: colors.successLight,
    dark: colors.successDark,
  },
  info: {
    main: colors.infoMain,
    light: colors.infoLight,
    dark: colors.infoDark,
  },
  grey: {
    100: colors.grey100,
    200: colors.grey200,
    300: colors.grey300,
    400: colors.grey400,
    500: colors.grey500,
    700: colors.grey700,
    900: colors.grey900,
    main: colors.grey500,
    contrastText: "rgba(0, 0, 0, 1)"
  },
  chart: {
    primaryGreen: colors.chartPrimaryGreen,
    primaryBlue: colors.chartPrimaryBlue,
    primaryTurquoise: colors.chartPrimaryTurquoise,
    primaryYellow: colors.chartPrimaryYellow,
    primaryGrey1: colors.chartPrimaryGrey1,
    primaryGrey2: colors.chartPrimaryGrey2,

    secondaryGreen: colors.chartSecondaryGreen,
    secondaryBlue: colors.chartSecondaryBlue,
    secondaryTurquoise: colors.chartSecondaryTurquoise,
    secondaryYellow: colors.chartSecondaryYellow,
    secondaryGrey1: colors.chartSecondaryGrey1,
    secondaryGrey2: colors.chartSecondaryGrey2,

    scope1: {
      100: colors.scope1100,
      200: colors.scope1200,
      300: colors.scope1300,
      500: colors.scope1500,
      700: colors.scope1700,
      900: colors.scope1900,
    },
    scope2: {
      100: colors.scope2100,
      200: colors.scope2200,
      300: colors.scope2300,
      500: colors.scope2500,
      700: colors.scope2700,
      900: colors.scope2900,
    },
    scope3: {
      100: colors.scope3100,
      200: colors.scope3200,
      300: colors.scope3300,
      500: colors.scope3500,
      700: colors.scope3700,
      900: colors.scope3900,
    },
  }
}