import { colors } from "../../../theme/colors"
import { fallbackColorsPrimary } from "../../../utils/fallbackColors"
import type { RankedCategoryEmission } from "../../../store/widgetContents/widgetContents.types"

export const parseEmissionsByCategory = (aggregates: RankedCategoryEmission[]) => {
  const fallbackColors = fallbackColorsPrimary()

  const getUniqueColor = () => {
    if (fallbackColors.length === 0) {
      return colors.chartSecondaryBlue
    }
    const randomIndex = Math.floor(Math.random() * fallbackColors.length);
    const color = fallbackColors[randomIndex];
    fallbackColors.splice(randomIndex, 1);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return color
  };

  return aggregates.map(({
    rank,
    carbonEquivalent,
    name,
    colour
  }) => ({
    id: rank,
    value: carbonEquivalent,
    name: `${name}`,
    color: colour ? `#${colour}` : getUniqueColor(),
  }))
}