import type { FC } from "react";
import { Grid } from "@mui/material";

import { useGetFuelAggregatesQuery, useGetUtilityAggregatesQuery } from "../../../store/widgetContents/widgetContents.api";
import { EmissionsPerFuel } from "../../../components/widgets/EmissionsPerFuel/EmissionsPerFuel.component";
import { WidgetUtilityAggregates } from "../../../components/WidgetUtilityAggregates/WidgetUtilityAggregates.component";

type ChartsTabProps = {
  locationId: number
}

export const ChartsTab: FC<ChartsTabProps> = ({ locationId }) => {
  const {
    data: utilitiesAggregates,
    isLoading: utilitiesAggregatesLoading
  } = useGetUtilityAggregatesQuery(
    { locationId },
    { refetchOnMountOrArgChange: true }
  )

  const {
    data: fuelAggregates,
    isLoading: fuelAggregatesLoading
  } = useGetFuelAggregatesQuery(
    { locationId },
    { refetchOnMountOrArgChange: true }
  )

  return (
    <Grid container spacing={3} flex={1}>
      <Grid item xs={12} xl={6}>
        <WidgetUtilityAggregates
          aggregates={utilitiesAggregates}
          aggregatesLoading={utilitiesAggregatesLoading}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <EmissionsPerFuel
          aggregates={fuelAggregates}
          aggregatesLoading={fuelAggregatesLoading}
        />
      </Grid>
    </Grid>
  )
}
