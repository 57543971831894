import { Box, Menu, styled } from "@mui/material";

export const StyledMenu = styled(Menu)(({ theme }) => ({
  padding: theme.spacing(0),
  "& .MuiPaper-root.MuiMenu-paper": {
    maxWidth: "350px",
    padding: theme.spacing(3),
  }
}))

export const StyledDraggableListWrapper = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== "isLoading" }
)<{ isLoading: boolean }>(({ isLoading }) => ({
  opacity: isLoading ? 0.5 : 1,
  pointerEvents: isLoading ? "none" : "auto",
  transition: "0.3s ease"
}))