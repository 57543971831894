import type { FC} from "react";
import { useMemo} from "react";
import { Box, List, Typography } from "@mui/material";

import bookUrl from "../../../assets/img/guides-book.png"
import { useGetUserGuidesQuery } from "../../../store/widgetContents/widgetContents.api";
import { DownloadGuideItem } from "./DownloadGuideItem/DownloadGuideItem.component";
import { parseResponse } from "./GuidesTile.utils";
import { StyledPaper } from "../widgets.styles";
import { StyledImageWrapper } from "./GuidesTile.styles";

export const GuidesTile: FC<{ title: string }> = ({ title }) => {
  const { data } = useGetUserGuidesQuery()
  const guides = useMemo(() => parseResponse(data), [data])

  return (
    <StyledPaper>
      <Typography
        variant="h2"
        color="text.dark"
      >
        {title}
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
      >
        <List>
          {guides.map(({ name, fileName }) => (
            <DownloadGuideItem
              key={fileName}
              name={name}
              fileName={fileName}
            />  
          ))}
        </List>

        <StyledImageWrapper>
          <img src={bookUrl} alt="" />
        </StyledImageWrapper>
      </Box>
    </StyledPaper>
  )
}