import { colors } from "../../../theme/colors"
import { fallbackColorsSecondary } from "../../../utils/fallbackColors"
import type { RankedEmission } from "../../../store/widgetContents/widgetContents.types"

export const parseTopEmissionSources = (aggregates: RankedEmission[]) => {
  const fallbackColors = fallbackColorsSecondary()
  
  const getUniqueColor = () => {
    if (fallbackColors.length === 0) {
      return colors.chartSecondaryBlue
    }
    const randomIndex = Math.floor(Math.random() * fallbackColors.length);
    const color = fallbackColors[randomIndex];
    fallbackColors.splice(randomIndex, 1);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return color
  };
    
  return aggregates.map(({
    rank,
    carbonEquivalent,
    entityName,
    colour
  }) => ({
    id: rank,
    value: carbonEquivalent,
    name: entityName,
    color: colour ? `#${colour}` : getUniqueColor(),
  }))
}