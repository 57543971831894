import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { Button } from "../../common/Button/Button.component";
import SvgIcon from "../../common/SvgIcon/SvgIcon.component";
import { ContactFormModal } from "../../ContactFormModal/ContactFormModal.component";
import { StyledPaper } from "../widgets.styles";
import { StyledCoverWrapper } from "./ErrorTile.styles";

export const ErrorTile: FC<{ title: string }> = ({ title }) => {
  const { t } = useTranslation()
  const [openContactFormModal, setOpenContactFormModal] = useState(false)

  return (
    <StyledPaper>
      <Typography
        variant="h2"
        color="text.dark"
        mb={1.5}
      >
        {title}
      </Typography>

      <StyledCoverWrapper>
        <Box mb={1.5}>
          <SvgIcon
            name="error3"
            color="error.main"
          />
        </Box>

        <Typography
          variant="h2"
          component="h3"
          color="text.dark"
          mb={1}
        >
          {t("errors:error")}
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          mb={2}
        >
          {t("widgets:reportError")}
        </Typography>

        <Button
          variant="outlined"
          size="tiny"
          onClick={() => setOpenContactFormModal(true)}
        >
          {t("widgets:contactUs")}
        </Button>
      </StyledCoverWrapper>

      <ContactFormModal
        open={openContactFormModal}
        onClose={() => setOpenContactFormModal(false)}
      />
    </StyledPaper>
  )
}
