import type { FC } from "react";
import { Box, Typography } from "@mui/material";

import { HorizontalBarChart } from "../../../charts/HorizontalBarChart/HorizontalBarChart.component";
import { NoDataCover } from "../../NoDataCover/NoDataCover.component";
import { StyledPaper } from "../../widgets.styles";

import { mock } from "./TopEmissionSourcesTileNoData.mock";

export const TopEmissionSourcesTileNoData: FC<{ title: string }> = ({ title }) => (
  <StyledPaper>
    <Typography
      variant="h2"
      color="text.dark"
      mb={3}
    >
      {title}
    </Typography>

    <Box
      flex={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ opacity: 0.3 }}
    >
      <HorizontalBarChart
        data={mock}
        unit=""
      />
    </Box>

    <NoDataCover />
  </StyledPaper>
)