export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  
  textPrimary: "#344054",
  textSecondary: "#667085",
  textDark: "#101828",
  textLight: "#EEEEEE",

  grey100: "#F9FAFB",
  grey200: "#F2F4F7",
  grey300: "#D0D5DD",
  grey400: "#A9AFB7",
  grey500: "#667085",
  grey700: "#344054",
  grey900: "#101828",

  primary100: "#E7F5E9",
  primary200: "#B8E2BD",
  primary300: "#59BB66",
  primary500: "#129E24",
  primary700: "#0D6F19",
  primary900: "#073F0E",

  errorMain: "#DE4238",
  errorLight: "#FEF3F2",
  errorDark: "#B42318",

  warningMain: "#FEC84B",
  warningLight: "#FFFAEB",
  warningDark: "#7A271A",

  successMain: "#129E24",
  successLight: "#E7F5E9",
  successDark: "#0D6F19",

  infoMain: "#317D9E",
  infoLight: "#ECF6FB",
  infoDark: "#246582",

  // charts NEW ONE
  chartPrimaryGreen: "#136F63",
  chartPrimaryBlue: "#317D9E",
  chartPrimaryTurquoise: "#008081",
  chartPrimaryYellow: "#E1C52F",
  chartPrimaryGrey1: "#5C7A78",
  chartPrimaryGrey2: "#B2C3C1",

  chartSecondaryGreen: "#4B9C92",
  chartSecondaryBlue: "#5699B6",
  chartSecondaryTurquoise: "#46B1B1",
  chartSecondaryYellow: "#F2DD6A",
  chartSecondaryGrey1: "#91A8A7",
  chartSecondaryGrey2: "#D8E4E4",

  scope1100: "#E0F0EE",
  scope1200: "#8BC2BB",
  scope1300: "#68AAA2",
  scope1500: "#459288",
  scope1700: "#1B6259",
  scope1900: "#144943",

  scope2100: "#ECF6FB",
  scope2200: "#9ECCE0",
  scope2300: "#6BACC8",
  scope2500: "#5699B6",
  scope2700: "#317D9E",
  scope2900: "#246582",

  scope3100: "#FFFAEB",
  scope3200: "#F0E7B9",
  scope3300: "#EEE098",
  scope3500: "#EBD561",
  scope3700: "#E1C52F",
  scope3900: "#CFB529",
}