import type { FC } from "react";
import { ListItem } from "@mui/material";

import { api } from "../../../../api/rest/api";
import useFileDownload from "../../../../hooks/useFileDownload";
import { getUserGuideFileConfig } from "../../../../store/userGuides/userGuides.config";
import { StyledButton } from "./DownloadGuideItem.styles";

type DownloadGuideItemProps = {
  name: string
  fileName: string
}

export const DownloadGuideItem: FC<DownloadGuideItemProps> = ({ name, fileName }) => {
  const getUserGuidePdf = async (filename: string) => api.request<Blob>({...getUserGuideFileConfig(filename)})
  const { download, downloading } = useFileDownload(async () => await getUserGuidePdf(fileName))

  return (
    <ListItem disableGutters>
      <StyledButton
        variant="text"
        size="tiny"
        onClick={async () => download(fileName)}
        disabled={downloading}
      >
        {name}
      </StyledButton>
    </ListItem> 
  )
}