import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import { PieChart } from "../../../charts/PieChart/PieChart.component";
import { NoDataCover } from "../../NoDataCover/NoDataCover.component";
import { StyledPaper } from "../../widgets.styles";

import { mockup } from "./ElectricityUsageTileNoData.mock";

export const ElectricityUsageTileNoData: FC<{ title: string }> = ({ title }) => {
  const { t } = useTranslation()

  return (
    <StyledPaper>
      <Typography
        variant="h2"
        color="text.dark"
        mb={1.5}
      >
        {title}
      </Typography>

      <Grid
        container
        spacing={3}
        alignItems="center"
        height="100%"
        sx={{ opacity: 0.3 }}
      >
        <Grid item xs={5}>
          <PieChart
            data={mockup}
            minHeight={250}
            legend
          />
        </Grid>

        <Grid item xs={7}>
          <Typography
            variant="body1"
            fontWeight={500}
            color="grey.300"
            mb={1.5}
          >
            {t("widgets:electricityUsageTile:mockupText")}
          </Typography>

          <Typography
            variant="body1"
            fontWeight={500}
            color="grey.300"
          >
            {t("widgets:electricityUsageTile:mockupTextPart2")}
          </Typography>
        </Grid>
      </Grid>

      <NoDataCover />
    </StyledPaper>
  )
}