import type { FC } from "react";
import { useEffect , useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { useAppSelector } from "../../../hooks/storeHooks";
import { selectUserData } from "../../../store/auth/auth.selectors";
import { useGetAllYearsWithDataAvailableQuery } from "../../../store/consumptions/consumptions.api";
import { useGetYearSettingValueConfigQuery, usePutYearSettingValueConfigMutation } from "../../../store/widgetContents/widgetContents.api";
import { Autocomplete } from "../../../components/common/Autocomplete/Autocomplete.component";
import type { AutocompleteOption } from "../../../components/common/Autocomplete/Autocomplete.types";
import { TextFieldSkeleton } from "../../../components/common/TextFieldSkeleton/TextFieldSkeleton.component";
import { CompleteDataReminder } from "./CompleteDataReminder/CompleteDataReminder.component";
import { WidgetsSettings } from "./WidgetsSettings/WidgetsSettings.component";

export const OverviewHeader: FC = () => {
  const { t } = useTranslation()
  const userData = useAppSelector(selectUserData)
  const shouldDisplay = !userData.isCompanyComplete && userData.roleName === "Admin"
  const [selectedYear, setSelectedYear] = useState<AutocompleteOption<number> | null>(null)
  const [putYearSetting, { isLoading: puttingYearLoading }] = usePutYearSettingValueConfigMutation()
  
  const {
    data: availableYears,
    isLoading: yearsLoading,
  } = useGetAllYearsWithDataAvailableQuery({ locationId: 0, includeMostRecent: true }, { refetchOnMountOrArgChange: true })

  const {
    data: yearSetting,
    isLoading: yearSettingLoading,
  } = useGetYearSettingValueConfigQuery()

  const settingsLoading = yearsLoading || yearSettingLoading
  const parsedAvailableYears = availableYears?.map(({ display, value }) => ({ value, label: display }))

  const handleChangeYear = async (year: AutocompleteOption<number>) => {
    setSelectedYear(year)
    try {
      await putYearSetting({ year: year.value }).unwrap()
    } catch(e) {
      // TODO: handle error
    }
  }

  useEffect(() => {
    if (parsedAvailableYears && yearSetting) {
      const currentSetting = parsedAvailableYears.find(({ value }) => yearSetting.year === value)
      if (currentSetting) {
        setSelectedYear(currentSetting)
      }
    }
  }, [availableYears, yearSetting])

  return (
    <>
      {shouldDisplay ? <CompleteDataReminder /> : null}

      <Box display="flex" mb={2}>
        <WidgetsSettings />

        <Box ml="auto">
          {settingsLoading
            ? <TextFieldSkeleton sx={{ minWidth: 127}} />
            : (
              <Autocomplete
                placeholder={t("widgets:yearSelectPlaceholder")}
                options={parsedAvailableYears ?? []}
                disableClearable
                onChange={async (_, data) => handleChangeYear(data)}
                value={selectedYear as AutocompleteOption<number>}
                sx={{ minWidth: 127 }}
                disabled={puttingYearLoading}
              />
            )}
        </Box>
      </Box>
    </>
  )
}