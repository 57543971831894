import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { MenuList as MuiMenuList } from "@mui/material";
import Divider from "@mui/material/Divider";

import { MenuListItem } from "./MenuListItem/MenuListItem.component";

import { useMenuConfig } from "./MenuList.config";

export const MenuList: FC<{open: boolean}> = ({ open }) => {
  const { t } = useTranslation()
  const menuItems = useMenuConfig(t)

  return (
    <MuiMenuList>
      {menuItems.map(({ text, iconName, navigateTo, disabled, visible }, index) => {
        const tooltipText = disabled && open 
          ? t("common:comingSoon")
          : disabled ? `${text} - ${t("common:comingSoon")}` : text
        
        return (
          <div key={text}>
            {index === menuItems.length-1 && <Divider sx={{ my: 1 }} />}
            {visible ? (
              <MenuListItem
                key={text}
                text={text}
                iconName={iconName}
                navigateTo={navigateTo}
                tooltipText={open && disabled || !open ? tooltipText : null}
                disabled={disabled}
              />
            ) : null}
          </div>
        )
      })}
    </MuiMenuList>
      
  );
}