import { Box, styled } from "@mui/material";

export const StyledWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: theme.spacing(2.75),
  marginBottom: theme.spacing(1),
}))

export const StyledDot = styled(Box)(({ theme }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: "50%",
  marginRight: theme.spacing(0.5),
}))