import { apiService } from "../store.service";
import type { UserSettingResponseModel } from "./userSettings.types";

import { getUserSettingsConfig, updateHideAddDataTooltipConfig, updateHideAddLocationTooltipConfig, updateHideDashboardCompanyTileConfig, updateHideUserTourConfig } from "./userSettings.config";

export const UserSettingsApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["UserSettings"]
  })
  .injectEndpoints({
    endpoints: build => ({
      getUserSettings: build.query<UserSettingResponseModel[], void>({
        query: () => ({
          ...getUserSettingsConfig,
        }),
      }),
      updateHideUserTour: build.mutation<void, { value: boolean }>({
        query: ({ value }) => ({
          ...updateHideUserTourConfig,
          params: { value }
        }),
      }),
      updateHideAddLocationTooltip: build.mutation<void, { value: boolean }>({
        query: ({ value }) => ({
          ...updateHideAddLocationTooltipConfig,
          params: { value }
        }),
        invalidatesTags: ["UserSettings"]
      }),
      updateHideAddDataTooltip: build.mutation<void, { value: boolean }>({
        query: ({ value }) => ({
          ...updateHideAddDataTooltipConfig,
          params: { value }
        }),
        invalidatesTags: ["UserSettings"]
      }),
      updateHideDashboardCompanyTile: build.mutation<void, { value: boolean }>({
        query: ({ value }) => ({
          ...updateHideDashboardCompanyTileConfig,
          params: { value }
        }),
        invalidatesTags: ["UserSettings"]
      }),
    }),
  });

export const {
  useGetUserSettingsQuery,
  useUpdateHideUserTourMutation,
  useUpdateHideAddLocationTooltipMutation,
  useUpdateHideAddDataTooltipMutation,
  useUpdateHideDashboardCompanyTileMutation,
} = UserSettingsApi