import { Box, styled } from "@mui/material";

export const StyledCoverWrapper = styled(Box)(({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}))