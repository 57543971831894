import type { FC} from "react";
import { useState } from "react";
import type { DraggableProvided } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useUpdateWidgetSelectionMutation } from "../../../../../store/widgetSelections/widgetSelections.api";
import { ButtonLoader } from "../../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import SvgIcon from "../../../../../components/common/SvgIcon/SvgIcon.component";
import { Switch } from "../../../../../components/common/Switch/Switch.component";
import { Toast } from "../../../../../components/common/Toast/Toast.component";
import type { WidgetSelectionResponseModel } from "../../../../../store/widgetSelections/widgetSelections.types";
import { StyledContentWrapper, StyledWrapper } from "./WidgetsSettingsItem.styles";

export type WidgetsSettingsItemProps = {
  widget: WidgetSelectionResponseModel
  draggableProvided: DraggableProvided
}

export const WidgetsSettingsItem: FC<WidgetsSettingsItemProps> = ({ widget, draggableProvided }) => {
  const { t } = useTranslation()
  const [error, setError] = useState(false)

  const [updateWidgetVisibility, { isLoading }] = useUpdateWidgetSelectionMutation()

  const handleChange = async () => {
    try {
      await updateWidgetVisibility({
        widgetId: widget.widgetId,
        show: !widget.show
      }).unwrap()
    } catch(e) {
      setError(true)
    }
  }

  return (
    <StyledWrapper
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
    >
      <SvgIcon
        name="draggable-dots"
        sx={{ mr: 0.5 }}
      />

      <StyledContentWrapper>
        <Typography variant="body1">
          {widget.name}
        </Typography>

        <Box
          display="flex"
          alignItems="center"
        >
          {isLoading 
            ? <ButtonLoader sx={{ mr: 1 }} />
            : null
          }
          <Switch
            checked={widget.show}
            onChange={handleChange}
            disabled={isLoading}
          />
        </Box>
      </StyledContentWrapper>

      <Toast
        open={error}
        title={t("errors:globalError")}
        severity="error"
        autoHideDuration={3000}
        onClose={() => setError(false)}
      />
    </StyledWrapper>
  )
}