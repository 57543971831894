import { theme } from "../../../../theme/theme";

export const mock = [
  {
    "id": 1,
    "value": 620,
    "name": "Gasoline",
    "color": theme.palette.grey[900]
  },
  {
    "id": 2,
    "value": 570,
    "name": "Oil",
    "color": theme.palette.grey[700]
  },
  {
    "id": 3,
    "value": 500,
    "name": "Methane",
    "color": theme.palette.grey[500]
  },
  {
    "id": 4,
    "value": 450,
    "name": "Wood",
    "color": theme.palette.grey[400]
  },
  {
    "id": 5,
    "value": 400,
    "name": "Diesel",
    "color": theme.palette.grey[300]
  },
  {
    "id": 6,
    "value": 350,
    "name": "Coal",
    "color": theme.palette.grey[200]
  }
]