import { theme } from "../../../../theme/theme";

export const mockup = [
  {
    "id": 1,
    "value": 21,
    "name": "Scope 2",
    "color": theme.palette.grey[200]
  },
  {
    "id": 2,
    "value": 79,
    "name": "Scope 3",
    "color": theme.palette.grey[300]
  }
]