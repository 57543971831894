import type { FC, MouseEvent } from "react";
import { useEffect , useState } from "react";
import type { DroppableProvided, DropResult } from "react-beautiful-dnd";
import { DragDropContext, Draggable, Droppable  } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { List, Typography } from "@mui/material";

import { useGetWidgetSelectionsQuery, useUpdateWidgetOrderMutation } from "../../../../store/widgetSelections/widgetSelections.api";
import { Button } from "../../../../components/common/Button/Button.component";
import SvgIcon from "../../../../components/common/SvgIcon/SvgIcon.component";
import { Toast } from "../../../../components/common/Toast/Toast.component";
import { WidgetsSettingsItem } from "./WidgetsSettingsItem/WidgetsSettingsItem.component";
import type { WidgetSelectionResponseModel } from "../../../../store/widgetSelections/widgetSelections.types";
import { StyledDraggableListWrapper, StyledMenu } from "./WidgetsSettings.styles";

export const WidgetsSettings: FC = () => {
  const { t } = useTranslation()
  const [widgets, setWidgets] = useState<WidgetSelectionResponseModel[]>()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [error, setError] = useState(false)

  const [updateWidgetsOrder, { isLoading: widgetsOrderLoading }] = useUpdateWidgetOrderMutation()
  const { data: widgetSelections, isFetching: widgetSelectionsFetching, error: widgetSelectionsError } = useGetWidgetSelectionsQuery()

  const handleOnDragEnd = async (result: DropResult) => {
    if (!result.destination || !widgetSelections) return;

    const items = Array.from(widgetSelections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setWidgets(items);

    try {
      await updateWidgetsOrder({
        widgetId: widgetSelections[result.source.index].widgetId,
        newPosition: result.destination.index + 1
      }).unwrap()
    } catch (e) {
      setError(true)
    }
  }

  const handleOpenSettings = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleCloseSettings = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (widgetSelections) {
      setWidgets(widgetSelections)
    }
  }, [widgetSelections, error])

  if (widgetSelectionsError) {
    return (
      <Typography
        variant="body1"
        color="error.main"
      >
        {t("errors:globalError")}
      </Typography>
    )
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        startIcon={<SvgIcon name="settings-filled" />}
        onClick={handleOpenSettings}
      />

      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseSettings}
      >
        <Typography
          variant="h2"
          component="h3"
          color="text.dark"
          display="flex"
          alignItems="center"
          mb={2}
        >
          <SvgIcon
            name="settings-filled"
            sx={{ color: "grey.500" }}
          />&nbsp;
          {t("widgets:settingsTitle")}
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          mb={3}
        >
          {t("widgets:settingsDescription")}
        </Typography>

        <StyledDraggableListWrapper isLoading={widgetsOrderLoading || widgetSelectionsFetching}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="settings">
              {(provided: DroppableProvided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {widgets?.map((widget, index) => (
                    <Draggable
                      key={widget.widgetId}
                      draggableId={widget.widgetId.toString()}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <WidgetsSettingsItem
                          widget={widget}
                          draggableProvided={draggableProvided}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </StyledDraggableListWrapper>
      </StyledMenu>

      <Toast
        open={error}
        title={t("errors:globalError")}
        severity="error"
        autoHideDuration={3000}
        onClose={() => setError(false)}
      />
    </>
  )
}