import type { FC} from "react";
import { useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { useGetTopEmissionSourcesQuery } from "../../../store/widgetContents/widgetContents.api";
import { HorizontalBarChart } from "../../charts/HorizontalBarChart/HorizontalBarChart.component";
import { Loader } from "../../common/Loader/Loader.component";
import { ErrorTile } from "../ErrorTile/ErrorTile.component";
import { TopEmissionSourcesTileNoData } from "./TopEmissionSourcesTileNoData/TopEmissionSourcesTileNoData.component";
import { parseTopEmissionSources } from "./TopEmissionSourcesTile.utils";
import { StyledPaper } from "../widgets.styles";

export const TopEmissionSourcesTile: FC<{ title: string }> = ({ title }) => {
  const {
    data: topEmissionSources,
    isLoading: topEmissionSourcesLoading,
    error: topEmissionSourcesError
  } = useGetTopEmissionSourcesQuery({ count: 10 }, { refetchOnMountOrArgChange: true })

  const parsedEmissions = useMemo(() => topEmissionSources 
    ? parseTopEmissionSources(topEmissionSources.topEmissions) 
    : [], [topEmissionSources])

  if (topEmissionSourcesLoading) {
    <StyledPaper>
      <Loader withoutText my="auto" />
    </StyledPaper>
  }

  if (topEmissionSourcesError) {
    return <ErrorTile title={title} />
  }

  if (!parsedEmissions.length) {
    return <TopEmissionSourcesTileNoData title={title} />
  }

  return (
    <StyledPaper>
      <Typography
        variant="h2"
        color="text.dark"
      >
        {title}
      </Typography>

      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <HorizontalBarChart
          data={parsedEmissions}
          unit={topEmissionSources?.unit ?? ""}
        />
      </Box>
    </StyledPaper>
  )
}