import type { TFunction } from "i18next";

export const shortcutsConfig = (t: TFunction) => [
  {
    id: 1,
    label: t("widgets:shortcutsTile:navToLocations"),
    navigateTo: "/locations",
  },
  {
    id: 2,
    label: t("widgets:shortcutsTile:navToReports"),
    navigateTo: "/reports",
  },
  {
    id: 3,
    label: t("widgets:shortcutsTile:navToScenarios"),
    navigateTo: "/scenarios",
  },
  // {
  //   id: 4,
  //   label: t("widgets:shortcutsTile:navToMarketplace"),
  //   navigateTo: "/marketplace",
  // },
]